<template>
  <div>
    <v-header></v-header>
    <div class="container">
      <template v-for="item in list">
        <span class="title">{{item.name}}</span>
        <div v-html="item.value" class="content"></div>
      </template>
    </div>
  </div>
</template>

<script>
  import {user} from "../../api";

  export default {
    name: "about",
    data() {
      return {
        list: []
      }
    },
    created() {
      this.getData();
    },

    methods: {
      async getData() {
        const res = await user.about(`codes=CODE_AUOUT_US&codes=CONNECTION_US`);
        for (const row of res.data) {
          row.value = row.value.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:block;"');
        }
        this.list = res.data;
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    height: calc(100vh - var(--header-height));
    overflow-y: auto;
    padding: var(--header-height) 28px 28px;

    .title {
      font-weight: bold;
      padding: 10px 0;
    }

    .content {
      p, span {
        letter-spacing: 4px;
        color: #999 !important;
      }
    }
  }
</style>
